<template>
  <div>
    <v-form ref="form" v-model="valid">
      <v-card class="card pa-6 mb-6">
        <h3 class="mb-2">
          <v-chip class="primary black--text mr-4" label>1</v-chip>Basic
        </h3>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="foodcourt.name"
              :rules="nameRules"
              label="Foodcourt Name"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="foodcourt.address"
              :rules="addressRules"
              label="Foodcourt Address"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="foodcourt.user_name"
              :rules="nameRules"
              label="Owner Name"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="foodcourt.pos"
              type="number"
              label="No. of POS"
              :rules="posRules"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              v-model="foodcourt.package_id"
              :items="packages"
              item-text="packageName"
              item-value="packageId"
              label="Package Name"
              :rules="itemRules"
              required
            ></v-select>
          </v-col>
        </v-row>
      </v-card>
      <v-card class="card pa-6">
        <h3 class="mb-2">
          <v-chip class="primary black--text mr-4" label>2</v-chip>Security
        </h3>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="foodcourt.cell"
              :rules="contactRules"
              type="number"
              label="Contact No.* (unique)"
              required
              prefix="+880"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="foodcourt.email"
              :rules="emailRules"
              label="E-mail* (unique)"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" v-show="!editFc">
            <v-text-field
              v-model="foodcourt.password"
              :rules="passwordRules"
              :append-icon="show1 ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
              :type="show1 ? 'text' : 'password'"
              @click:append="show1 = !show1"
              label="Password"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" v-show="!editFc">
            <v-text-field
              v-model="foodcourt.password_confirmation"
              :rules="confirmPasswordRules"
              :append-icon="show2 ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
              :type="show2 ? 'text' : 'password'"
              @click:append="show2 = !show2"
              label="Confirm Password"
              required
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card>
      <v-card-actions class="pr-0 mt-4">
        <v-spacer></v-spacer>
        <v-btn
          v-show="editFc"
          outlined
          text
          color="primary"
          @click="cancelEdit"
          >Cancel</v-btn
        >
        <v-btn
          :disabled="!valid || loading"
          :loading="loading"
          class="primary px-4"
          @click="addFoodcourt"
        >
          Continue
        </v-btn>
      </v-card-actions>
    </v-form>
  </div>
</template>

<script>
import { eventBus } from "../../main";
import store from "../../store";
import packages from "../../dummy/packages";
export default {
  props: ["editFc"],
  data() {
    return {
      valid: false,
      loading: false,
      foodcourt: {
        name: "",
        address: "",
        user_name: "",
        email: "",
        cell: "",
        password: "",
        password_confirmation: "",
        package_id: 7,
        pos: null,
      },
      show1: false,
      show2: false,
      packages,

      data: "",

      nameRules: [(v) => !!v || "Name is required"],
      addressRules: [(v) => !!v || "Address is required"],
      contactRules: [
        (v) => !!v || "Contact No is required",
        (v) => (v && v.length == 10) || "Must be 10 Characters",
      ],
      itemRules: [(v) => !!v || "Item is required"],
      posRules: [(v) => !!v || "POS is required"],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      passwordRules: [
        (v) => !!v || "Please type password.",
        (v) => (v && v.length >= 6) || "minimum 6 characters",
      ],
      confirmPasswordRules: [
        (v) => !!v || "type confirm password",
        (v) =>
          v === this.foodcourt.password ||
          "The password confirmation does not match",
      ],
    };
  },

  created() {
    if (this.editFc) {
      // console.log("coming to edit fc", store.getters.FcData);
      this.data = store.getters.FcData;
      this.foodcourt.name = this.data.foodCourt.name;
      this.foodcourt.address = this.data.foodCourt.address;
      this.foodcourt.user_name = this.data.foodCourt.user.name;
      this.foodcourt.cell = this.data.foodCourt.user.cell;
      this.foodcourt.email = this.data.foodCourt.user.email;
      this.foodcourt.package_id = this.data.foodCourt.subscription.packages[0].id;
      this.foodcourt.pos = this.data.foodCourt.subscription.number_of_pos;
    }
  },

  methods: {
    cancelEdit() {
      eventBus.$emit("cancelEdit")
    },
    addFoodcourt() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.valid = false;
        let foodcourtPayload = {};
        Object.assign(foodcourtPayload, this.foodcourt);
        foodcourtPayload.cell = "0" + this.foodcourt.cell;
        // console.log(foodcourtPayload)
        store
          .dispatch("addFoodcourt", foodcourtPayload)
          .then((res) => {
            // console.log("res in foodcourtForm", res);
            this.loading = false;
            eventBus.$emit("snackbar", {
              snackbarColor: "#31A274",
              snackbarText: `${res.data.message}`,
            });
            this.$emit("stepNo", 2);
            // this.$refs.form.reset()
          })
          .catch((err) => {
            console.log(err);
            this.valid = true;
            this.loading = false;
            eventBus.$emit("snackbar", {
              snackbarColor: "#FF5252",
              snackbarText: "Something Went Wrong!",
            });
          });
      }
    },
  },
};
</script>
